/* Base button styles */
.button {
  display: inline-flex;
  align-items: center;
  padding: 12px 30px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 30px;
  transition: background-color 0.3s, border-color 0.3s;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  width: fit-content;
  margin: 5px 0;
}

/* Filled button style */
.button.filled {
  background-color: #023a65;
  border: 1px solid #023a65;
  color: #ffffff;
}

.button.filledLight {
  background-color: #356BBB;
  border: 1px solid #356BBB;
  color: #ffffff;
}

.button.outline {
  background-color: transparent;
  color: #366bbb;
  border: 1px solid #366bbb;
}

.button.allBlue {
  background-color: #c7e7ff;
  border: 1px solid #c7e7ff;
  color: #366bbb;
}

.button.oulinedBlurBg {
  background-color: #ffffff66;
  border: 1px solid #ffffff;
  color: #ffffff;
}

.button.transparent {
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
}

.button.outlinedBlack {
  background-color: transparent;
  border: 1px solid black;
  color: black;
}

.button.filledLightBlue {
  background-color: #3267FF;
  border: 1px solid #3267FF;
  color: #ffffff;
}

.button-icon {
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
}



@media screen and (max-width: 600px) {
  .button {
    padding: 10px 20px; /* Reduce padding for a compact layout */
    font-size: 10px; /* Adjust font size for smaller screens */
    border-radius: 20px; /* Adjust border-radius for smaller screens */
    margin: 3px 0; /* Reduce vertical margin */
    width: 100%; /* Full width for better tap interaction */
    justify-content: center; /* Center-align content */
  }

  .button-icon {
    margin-right: 5px; /* Reduce spacing for smaller screens */
  }

  /* Adjust variations */
  .button.filled,
  .button.filledLight,
  .button.outline,
  .button.allBlue,
  .button.oulinedBlurBg,
  .button.transparent,
  .button.outlinedBlack,
  .button.filledLightBlue {
    width: 100%; /* Ensure full width for all button styles */
    padding: 10px; /* Ensure consistent padding across variations */
    font-size: 10px; /* Keep font size consistent */
  }

  .button.transparent,
  .button.outlinedBlack {
    text-align: center; /* Center-align text for these styles */
  }
}
