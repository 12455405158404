.carousel-container {
    perspective: 1000px;
    position: relative;
    margin: 0 auto;
    width: 80%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  
  .carousel-item {
    flex: 0 0 20%;
    opacity: 0.5;
    transform: scale(0.7) translateZ(-200px);
    transition: transform 1s, opacity 1s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  
  .carousel-item.active {
    transform: scale(1) translateZ(0);
    opacity: 1;
    z-index: 10;
  }
  
  .card {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .card img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
  }
  
  .cardContent h3 {
    font-size: 20px;
    color: #333;
  }
  
  .cardContent p {
    font-size: 14px;
    color: #666;
  }
  
  .carousel-navigation {
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
  }
  
  .carousel-navigation button {
    background-color: rgba(25, 136, 255, 0.7);
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 24px;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .carousel-navigation button:hover {
    background-color: rgba(25, 136, 255, 1);
  }
  