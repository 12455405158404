.coursesCard {
  background-color: #c9e2ff;
  padding: 10px;
  display: flex;
  gap: 30px;
  border-radius: 20px;
  transition: width 2s ease-in-out;
  margin-bottom: 50px;
  /* height: 400px; */
  width: 800px;
  height: 500px;
  padding: 0 0 20ox 0;
}

.coursesCard .courseImg {
  width:50% !important;
  height: 100%;
  /* height: 300px; */
  padding-right: 10px;
  border-radius: 20px;
}

.coursesCard .detailsDiv {
  text-align: left;
  margin: 30px 20px 20px 5px;
}

.btnDiv {
  display: flex;
  justify-content: end;
  margin-top: 50px;
}

.hrLine {
  outline: none;
}

.logo {
  width: 80px;
}

.currentCard {
  transform: scale(1, 1);
}

.card {
  transform: scale(0.7, 0.7);
}

@media screen and (max-width: 900px) {
  .coursesCard {
    flex-direction: column;
    width: 90vw;
  }

  .coursesCard .courseImg {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .coursesCard .detailsDiv {
    padding: 20px 20px 0 20px;
  }

  .btnDiv {
    display: flex;
    justify-content: center;
    /* margin-top: 50px; */
  }
}




@media screen and (max-width: 600px) {
  .coursesCard {
    flex-direction: column;
    width: 100% ;
    height: auto; /* Allow dynamic height */
    padding: 10px;
    gap: 15px; 
     margin-bottom: 30px;
  }

  .coursesCard .courseImg {
    width: 100% !important;
    height: 150px; /* Set a fixed height for better layout on small screens */
    object-fit: cover; /* Ensure image scales proportionally */
    border-radius: 15px;
  }

  .coursesCard .detailsDiv {
    margin: 10px 0;
    text-align: center; /* Center-align for better mobile readability */
  }

  .btnDiv {
    justify-content: center;
    margin-top: 20px;
    padding: 10px 0;
  }

  .logo {
    width: 60px; /* Reduce logo size for smaller screens */
  }

  .currentCard {
    transform: scale(1);
  }

  .card {
    transform: scale(0.8);
  }
}
