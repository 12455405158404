.carousel {
    width: 100%;
    max-width: 100%;
    height: auto;
}

@media screen and (max-width: 600px) {
    .carousel {
      width: 100%; /* Ensure full width on mobile */
      height: auto; /* Maintain aspect ratio */
      overflow: hidden; /* Hide any overflow content */
    }
  }
  