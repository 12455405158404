.contactInfo {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;      /* Center vertically */
  height: 600px;
  padding: 10px 30px;            /* Add spacing between the map and contact details */
}

.contactDetail {
  width: 40%;               /* Set width to 50% for both sections */
  height: 100%;
  margin-left: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;   /* Keep contact items aligned to the left */
  padding-right: 30px;
}

.contactMap {
  width: 70%;               /* Set width to 50% */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactList {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.contactItem {
  display: flex;
  gap: 10px;
  align-items: center;
}

.icon {
  background-color: #fff;
  padding: 3px;
  width: 30px;
  height: 30px;
  display: grid;
  place-content: center;
  border-radius: 100%;
  box-shadow: 0px 3px 6px 0px #00000029;
}

.blue {
  width: 90%;
  height: 80%;
  background: #0049b4a3;
  border-radius: 20px;
  padding: 20px;
}

@media screen and (max-width: 900px) {
  .contactInfo {
    flex-direction: column;
    height: auto;
    justify-content: flex-start; /* Adjust layout for smaller screens */
  }

  .contactDetail,
  .contactMap {
    width: 100%;              /* Full width for both sections */
  }

  .contactMap {
    height: 25rem;
  }
}



@media screen and (max-width: 600px) {
  .contactInfo {
    flex-direction: column; /* Stack sections vertically */
    height: auto; /* Adjust height dynamically */
    padding: 20px; /* Adjust padding for better spacing */
    justify-content: flex-start;
    align-items: center;
  }

  .contactDetail {
    width: 100%; /* Full width for contact details */
    margin-left: 0; /* Remove left margin */
    padding-right: 0; /* Remove right padding */
    align-items: center; /* Center align items */
    text-align: center; /* Center align text */
  }

  .contactMap {
    width: 100%; /* Full width for the map */
    height: 20rem; /* Adjust height for mobile screens */
    margin-top: 20px; /* Add spacing between sections */
  }

  .contactList {
    margin-top: 20px; /* Reduce top margin */
    gap: 10px; /* Increase gap for better readability */
  }

  .contactItem {
    flex-direction: column; /* Stack icon and text vertically */
    gap: 5px; /* Adjust gap */
    align-items: center; /* Center align items */
  }

  .icon {
    width: 40px; /* Increase icon size for better tap interaction */
    height: 40px;
    box-shadow: 0px 2px 4px 0px #00000029; /* Adjust shadow for smaller screens */
  }

  .blue {
    width: 100%; /* Full width for mobile */
    padding: 15px; /* Adjust padding */
    border-radius: 15px; /* Reduce border radius */
    text-align: center; /* Center align content */
  }
}
