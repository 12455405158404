.fromTheBlogsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 50px 80px;
}

.fromTheBlogsWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
    max-width: 100%;
}


@media screen and (max-width: 600px) {
    .fromTheBlogsContainer {
      padding: 20px 10px; /* Reduce padding for smaller screens */
      gap: 15px; /* Adjust gap for compact spacing */
    }
  
    .fromTheBlogsWrapper {
      display: flex; /* Switch from grid to flex for better stacking */
      flex-direction: column; /* Stack blog items vertically */
      gap: 15px; /* Add spacing between items */
      width: 100%;
    }
  }
  