.banner {
  background-color: #002744;
  display: flex;
  padding: 10px 70px;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {
  .banner {
    flex-direction: column;
    position: relative;
    height: 70px;
    justify-content: center;
  }

  .banner button {
    position: absolute;
    top: 50px;
  }
}


@media screen and (max-width: 600px) {
  .banner {
    flex-direction: column; /* Stack content vertically */
    padding: 15px 20px; /* Reduce padding for smaller screens */
    align-items: center; /* Center-align items */
    gap: 15px; /* Add spacing between stacked elements */
    text-align: center; /* Center-align text for better readability */
  }
}
