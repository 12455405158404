@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

:root {
  --whiteColor: #ffffff;
  --DarkBlue: #1d4c92;
  --Black: #000;
}

body {
  margin: 0;
  padding: 0 ;
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  width: 100%;
  height: auto;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Manrope", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}
