.dentistrySection {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-radius: 10px;
    background-color: #f7f9fc;
  }
  
  .leftSide {
    width: 65%;
    position: relative;
  }
  
  .dentistryImage {
    width: 100%;
    border-radius: 15px;
    height: 500px;
  }
  
  .overlayText {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  }
  
  .overlayText h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .overlayText p {
    font-size: 1.2rem;
  }
  
  .rightSide {
    width: 33%;
    background-color: #1d4c92; /* Adjusted to a light blue color */
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 500px;
  }
  
  .rightSide h5 {
    color: #7a869a;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .rightSide h2 {
    color: #f7f9fc;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.5;
  }
  
  .contactInfo {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .contactInfo p {
    color: #f7f9fc;
    font-size: 1rem;
    font-weight: bold;

  }
  
  .contactBtn {
    padding: 10px 20px;
    background-color: #ffffff66;
    border: 1px solid #ffffff;
    color: #ffffff;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contactBtn:hover {
    background-color: #1d3557;
    color: white;
  }
  


  @media screen and (max-width: 950px) {
    .dentistrySection {
      flex-direction: column;
      padding: 10px;
      gap: 20px;
    }
  
    .leftSide {
      width: 100%;
    }
  
    .dentistryImage {
      width: 100%;
      height: auto; /* Adjust to maintain aspect ratio */
      border-radius: 10px;
    }
  
    .overlayText {
      position: static; /* Let text follow normal flow */
      margin-top: 10px;
      text-align: center;
      color: #1d4c92;
      text-shadow: none; /* Optional: Remove text shadow for better readability */
    }
  
    .overlayText h1 {
      font-size: 2rem;
      margin-bottom: 10px;
    }
  
    .overlayText p {
      font-size: 1rem;
    }
  
    .rightSide {
      width: 100%;
      height: auto; /* Adjust height */
      padding: 15px;
      border-radius: 10px;
    }
  
    .rightSide h5 {
      font-size: 0.9rem;
      margin-bottom: 10px;
    }
  
    .rightSide h2 {
      font-size: 1.2rem;
      line-height: 1.4;
    }
  
    .contactInfo {
      flex-direction: column;
      align-items: flex-start; /* Align items to the start */
      margin-top: 15px;
      gap: 10px;
    }
  
    .contactInfo p {
      font-size: 0.9rem;
      text-align: left;
    }
  
    .contactBtn {
      width: 100%;
      padding: 10px;
      font-size: 0.9rem;
      text-align: center;
    }
  }
  