.slider {
    width: 100%;
    height: 500px;
    position: relative;
    perspective: 1000px;
    overflow: hidden;
  }
  
  .card {
    width: 35%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) scale(0.85);
    transition: transform 0.5s ease, opacity 0.5s;
    opacity: 0.5;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }
  
  .card.active {
    transform: translateX(-50%) scale(1);
    opacity: 1;
    z-index: 10;
  }
  
  .card-content {
    color: #fff;
    text-align: center;
    transform: translateY(20%);
  }
  
  .control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }




  @media screen and (max-width: 600px) {
    .slider {
      height: 300px; /* Reduce slider height for smaller screens */
      perspective: 800px; /* Adjust perspective for a more compact layout */
    }
  
    .card {
      width: 80%; /* Increase card width for better visibility */
      height: 80%; /* Adjust height to maintain proportions */
      transform: translateX(-50%) scale(0.8); /* Adjust scaling for smaller screens */
      border-radius: 15px; /* Reduce border-radius for compact design */
    }
  
    .card.active {
      transform: translateX(-50%) scale(0.95); /* Slightly reduce scaling for active card */
      z-index: 10;
    }
  
    .card-content {
      font-size: 14px; /* Adjust text size for better readability */
      transform: translateY(15%); /* Slightly lower the content */
      padding: 10px; /* Add padding for better spacing */
    }
  
    .control {
      font-size: 20px; /* Adjust control size for smaller screens */
      top: 40%; /* Adjust positioning for better alignment */
    }
  
    .prev {
      left: 5px; /* Reduce left spacing */
    }
  
    .next {
      right: 5px; /* Reduce right spacing */
    }
  }
  