.formContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin: 30px auto;
    padding: 20px;
    border-radius: 44px;
    backdrop-filter: blur(20px);
    background: rgba(201, 226, 255, 0.2);

}

.formContainer input::placeholder {
    color: white;
}

.formContainer {
    color: white;
}

.formContainer input[type="date"]::-webkit-calendar-picker-indicator {
    color: white !important;
}

.formContainer img {
    width: 500px;
}

.form {
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding: 70px 50px;
}

.image {
    width: 100%;
    height: 100%;
}

.image>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.formMain {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.flexform {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

@media screen and (max-width: 1100px) {

    .formContainer {
        flex-direction: row;
        width: 90%;
        margin: 10px auto;
        padding: 20px;
    }

    .formContainer img {
        width: 350px;
    }

    .form {
        padding: 50px 20px;
    }
}

@media screen and (max-width: 720px) {

    .formContainer {
        flex-direction: column;
        width: 90%;
        margin: 10px auto;
        padding: 20px;
    }

    .formContainer img {
        width: 100%;
    }

    .form {
        padding: 50px 20px;
    }
}