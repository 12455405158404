.textfield {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  position: relative;
}

.textfield label {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  text-align: left;
  color: #000;
  margin-bottom: 4px;
}

.textfield input {
  border-radius: 10px;
  border: 1px solid #426b8b;
  outline: none;
  padding: 15px 10px;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  color: #426b8b;
}
::placeholder {
  color: #426b8b;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #426b8b;
}

.dropdown {
  position: relative;
}

.selectedOption {
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #426b8b;
  outline: none;
  padding: 15px 10px;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #426b8b;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 1;
  color: #426b8b;
  font-size: 12px;
}

.option {
  padding: 8px;
  cursor: pointer;
}

.option:hover {
  background-color: #f0f0f0;
}



@media screen and (max-width: 600px) {
  .textfield {
    margin-bottom: 8px; /* Reduce margin for compact spacing */
  }

  .textfield label {
    font-size: 12px; /* Reduce font size for better fit */
    line-height: 16px; /* Adjust line height */
    margin-bottom: 2px; /* Reduce space below label */
  }

  .textfield input {
    border-radius: 8px; /* Slightly reduce border-radius */
    padding: 12px 8px; /* Adjust padding for smaller inputs */
    font-size: 10px; /* Reduce font size for better fit */
  }

  ::placeholder {
    font-size: 10px; /* Reduce placeholder font size */
  }

  .dropdown {
    width: 100%; /* Ensure full width */
  }

  .selectedOption {
    border-radius: 8px; /* Adjust border-radius */
    padding: 12px 8px; /* Reduce padding */
    font-size: 10px; /* Adjust font size */
  }

  .options {
    max-height: 150px; /* Reduce max height for dropdown options */
    font-size: 10px; /* Adjust font size for options */
    overflow-y: auto; /* Maintain scrolling for compact dropdown */
  }

  .option {
    padding: 6px; /* Reduce padding for options */
  }

  .option:hover {
    background-color: #e0e0e0; /* Subtle hover effect for mobile */
  }
}
