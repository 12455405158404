.sliderContainer {
    position: relative;
    width: 100%;
    height: 400px; /* Adjust height as per your design */
    overflow: hidden;
  }
  
  .slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
  }
  
  .slide {
    min-width: 100%; /* Each slide will take up full width */
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    display: none; /* Initially, slides are hidden */
  }
  
  .active {
    opacity: 1;
    display: flex; /* Show the active slide */
    justify-content: space-around;
  }
  
  .detailsDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .docImg {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .prevButton,
  .nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
  }
  
  .prevButton {
    left: 10px;
  }
  
  .nextButton {
    right: 10px;
  }
  
  .prevButton:hover,
  .nextButton:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  

  @media screen and (max-width: 600px) {
    .sliderContainer {
      height: 300px; /* Reduce height for smaller screens */
    }
  
    .slider {
      flex-direction: column; /* Stack slides vertically if needed */
      width: 100%; /* Ensure full width */
    }
  
    .slide {
      min-width: 100%; /* Keep each slide full width */
      display: flex; /* Ensure the active slide is displayed */
      flex-direction: column; /* Stack content within the slide vertically */
      align-items: center; /* Center-align content */
    }
  
    .detailsDiv {
      padding: 10px; /* Add padding for better spacing */
      align-items: center; /* Center-align text */
      text-align: center; /* Ensure proper alignment for smaller screens */
    }
  
    .docImg {
      width: 100px; /* Reduce image size for mobile */
      height: 100px;
      margin-bottom: 10px; /* Add spacing below the image */
    }
  
    .prevButton,
    .nextButton {
      top: 90%; /* Move navigation buttons below the slider */
      font-size: 18px; /* Adjust button size for mobile */
      padding: 8px; /* Reduce padding */
      width: 30px; /* Set a fixed size */
      height: 30px;
      border-radius: 50%; /* Make buttons circular */
      background-color: rgba(0, 0, 0, 0.7); /* Ensure visibility on smaller screens */
    }
  
    .prevButton {
      left: 20%; /* Position closer to the center */
    }
  
    .nextButton {
      right: 20%; /* Position closer to the center */
    }
  
    .prevButton:hover,
    .nextButton:hover {
      background-color: rgba(0, 0, 0, 0.9); /* Subtle hover effect */
    }
  }
  