.headerSec {
  background-image: url("../../assets/blogsHeaderBg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 120px 0px 0px 0px;
  height: auto;
  padding: 100px 30px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.blogsSec {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 60px;
}

@media screen and (max-width: 900px) {
  .headerSec {
    padding: 70px 20px;
    margin: 15px 0px 0px 0px;
  }

  .blogsSec {
    grid-template-columns: repeat(2, 1fr);
    padding: 30px;
  }
}

@media screen and (max-width: 450px) {
  .headerSec {
    padding: 50px 10px;
  }

  .blogsSec {
    grid-template-columns: repeat(1, 1fr);
    padding: 20px;
  }
}
