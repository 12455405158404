.card {
    padding: 20px;
    border-radius: 25px;
    text-align: left;
    box-shadow: 0px 10px 40px 0px #00000026;
    background-color: white;
}

.imgDiv {
    width: 100%;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 25px;
    height: 300px;
}

.imgDiv img {
    width: 120px;
    height: 120px;
    position: relative;
    left: -10px;
    top: -10px;
}

.imgDiv .commingSoonDiv {
    display: flex;
    justify-content: end;
}

.imgDiv .comingSoonSpan {
    background-color: #FFEBAE;
    color: #A27A00;
    border-top-left-radius: 10px;
    width: 150px;
    padding: 5px;
    text-align: center;
}

.btnDiv {
    display: flex;
    justify-content: end;
    padding: 10px;
}



@media screen and (max-width: 600px) {
    .card {
      padding: 15px; /* Reduce padding for smaller screens */
      border-radius: 15px; /* Adjust border-radius for compact layout */
      text-align: center; /* Center text for better readability */
      box-shadow: 0px 5px 20px 0px #00000026; /* Subtle shadow for smaller screens */
    }
  
    .imgDiv {
      height: 200px; /* Reduce height for compact layout */
      border-radius: 15px; /* Match the card's border-radius */
      background-size: contain; /* Adjust background size for mobile */
    }
  
    .imgDiv img {
      width: 80px; /* Reduce image size for smaller screens */
      height: 80px;
      position: relative;
      left: 0; /* Align image properly */
      top: 0;
      margin: 10px auto; /* Center image */
    }
  
    .imgDiv .commingSoonDiv {
      justify-content: center; /* Center the "coming soon" div */
    }
  
    .imgDiv .comingSoonSpan {
      width: auto; /* Allow width to adjust dynamically */
      font-size: 0.9rem; /* Adjust font size for readability */
      padding: 5px 10px; /* Adjust padding */
      border-radius: 5px; /* Reduce border-radius */
    }
  
    .btnDiv {
      justify-content: center; /* Center the button container */
      padding: 5px; /* Reduce padding */
    }
  }
  