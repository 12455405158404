/* Carousel container */
.carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  /* Carousel inner content */
  .carouselInner {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  /* Doctor card container */
  .doctor {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    background-image: url('../../assets/course/proffesorBg.svg');
    background-position: right bottom;
    background-size: 850px 600px;
    height: 600px;
    background-repeat: no-repeat;
    align-items: center;
    overflow: hidden;
    padding: 10px;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    color: white; /* Set all text to white */
  }
  
  .doctor.show {
    opacity: 1;
    visibility: visible;
  }
  
  .doctor.hide {
    opacity: 0;
    display: none;
  }
  
  /* Doctor image */
  .docImg {
    width: 450px;
    height: 630px;
    position: relative;
  }
  
  /* Info grid for experience */
  .infoGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  /* Info span container */
  .infoGrid span {
    display: flex;
    gap: 10px;
    color: white; /* Ensure text in spans is white */
  }
  
  .detailsDiv {
    width: max-content;
    position: relative;
    left: 15px;
    top: -20px;
    margin-left: 30px;
    color: white; /* Ensure all text inside the detailsDiv is white */
  }
  
  .detailDivH1{
    margin-top: '20px';
    margin-bottom: '10px'; 
    text-decoration: 'underline white solid 1px';
    text-underline-offset: 5px;
  }
  /* Dots container */
  .dots {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px; /* Adjust this to position the dots */
    width: 100%;
    gap: 10px; /* Space between the dots */
  }
  
  /* Individual dot */
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5); /* White color for dots */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Active dot styling */
  .dot.active {
    background-color: white; /* Highlight active dot */
  }
  


  @media screen and (max-width: 950px) {
    .carousel {
      height: auto; /* Allow the carousel to adjust its height dynamically */
    }
  
    .carouselInner {
      
      flex-direction: column; /* Stack content vertically */
    }
  
    .doctor {
      flex-direction: column; /* Stack doctor image and details vertically */
      background-size: cover; /* Adjust background size for better fit */
      height: 360px; /* Let the container adjust its height */
      padding: 20px; /* Add padding for a better layout */
      text-align: center; /* Center-align text for mobile */
    }
  
    .docImg {
      left: 80px;
      bottom: 120px;
      width: 200px; /* Reduce image size for smaller screens */
      height: auto; /* Maintain aspect ratio */
    }
  
    .infoGrid {
      grid-template-columns: 1fr; /* Single column layout for smaller screens */
      gap: 10px; /* Reduce gap between grid items */
      width: 100%; /* Full width */
    }
  
    .infoGrid span {
      padding-left: 10px;
      justify-content: left; /* Center-align text */
      text-align: left; /* Align content in the center */
      gap: 5px; /* Reduce spacing between elements */
    }
  
    .detailsDiv {
      position: relative;
      margin: 10px 0;
      top: 140px;
      left:-100px;
      text-align: center;
    }
  
    .dots {
      bottom: 10px; /* Move dots closer to the bottom */
      gap: 8px; /* Reduce gap between dots */
    }
  
    .dot {
      width: 8px; /* Reduce dot size */
      height: 8px;
    }
  
    .dot.active {
      background-color: rgba(255, 255, 255, 0.8); /* Slightly dimmer active dot */
    }
  }
  