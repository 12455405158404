.carouselContainer {
  width: 100%;
  height: 400px;
  max-width: 400px; /* Adjusts for mobile screens */
  margin: auto;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%;
}

.carouselItem {
  flex: 0 0 100%; /* Each item takes up 100% of the container's width */
  width: 100%; /* Ensure items are the same width as the container */
  height: 100%;
  opacity: 0; /* Hide items that are not active */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.carouselItem.active {
  opacity: 1; /* Show the active item */
  transform: translateX(0); /* Center the active item */
}

.card {
  height: 100%; /* Full height of the container */
  background-color: rgba(0, 163, 255, 0.2);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  color: #000;
}

.cardImage {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.cardContent {
  padding: 10px;
}

.badge {
  display: inline-block;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border-radius: 20px;
  font-size: 12px;
  margin-bottom: 10px;
}

.navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.navigation button {
  position: absolute;
  top: 90%;
  transform: translateY(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  background-color: rgba(25, 136, 255, 0.7);
  border: none;
  cursor: pointer;
  font-size: 18px;
  z-index: 10;
}

.navigation button:first-child {
  left: 0px;
}

.navigation button:last-child {
  right: 0px;
}

.navigation button:hover {
  background-color: rgba(25, 136, 255, 1);
}
