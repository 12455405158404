.faqContainer {
    width: 100%;
    border-radius: 20px;
    border-bottom: #004E87 solid 2px;
    border-right: #004E87 solid 1px;
    padding: 20px;
    margin-block: 20px;
    
}

.questionDiv {
    display: flex;
    justify-content: space-between;
}

.questionDiv img {
    width: 20px;
    cursor: pointer;
}


@media screen and (max-width: 950px) {
    .faqContainer {
      width: 100%;
      border-radius: 15px; /* Adjust for smaller screens */
      border-bottom: #004E87 solid 2px;
      border-right: none; /* Remove right border for cleaner design */
      padding: 15px; /* Reduce padding for better fit */
      margin-block: 15px; /* Adjust margin for compact layout */
    }
  
    .questionDiv {
      display: flex;
      justify-content: space-between;
      /* Stack content vertically */
      align-items: center; /* Align items to the start */
      gap: 3px; /* Add spacing between elements */
    }


    .questionDiv img {
    
      width: 20px; /* Slightly increase size for better tap interaction */
      cursor: pointer;
      align-self: flex-end; /* Place the image at the end for better alignment */
    }
  }
  