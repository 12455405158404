.sec {
  margin: 150px 0px 30px 0px;
  padding: 0 50px;
}

.heroDiv {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  padding: 10px 0px;
}

.heroImg {
  width: 70%;
}

.sec2 {
  display: flex;
  background-image: url("../../assets/artist_sketch.svg");
  background-position: center;
  background-origin: border-box;
  background-repeat: no-repeat;
  padding: 30px;
  height: 500px;
  background-color: rgba(228, 240, 255, 1);
}

.sec3 {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  align-self: center;
  text-align: left;
  margin: 30px;
  flex-direction: column;
}

.sec3div {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 10px;
  width: 100%;
  align-items: center;
}

.demoImg {
  width: 100%;
}

.sec4 {
  background-color: #f5f5f4;
  text-align: center;
  padding-block: 30px;
}

.sec5 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  padding-block: 30px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/dentists_bg.png");
}

.sec5 .dentistCardContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 100px;
}

.faqSec {
  padding: 100px;
  display: flex;
  justify-content: space-around !important;
  gap: 30px;
}

.faqDescription {
  text-align: left;
  width: 20%;
}

.faqs {
  width: 50%;
}

.sec6 {
  background-position: center;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  padding: 100px 30px;
  background: url("../../assets/infoBg.svg");
  height: 700px;
  display: flex;
  align-items: end;
}

.sec3 {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    text-align: left;
    margin: 30px;
    flex-direction: column;
}

.sec3div {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 10px;
    width: 100%;
}

.demoImg {
    width: 90%;
}

.sec4 {
    background-color: #F5F5F4;
    text-align: center;
    padding-block: 30px;

}

.sec5 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding-block: 30px;
    background:
        linear-gradient(rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)),
        url('../../assets/dentists_bg.png');

}

.sec5 .dentistCardContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 100px;
}

.faqSec {
    padding: 100px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.faqDescription {
    text-align: left;
    width: 25%;
}

.faqs {
    width: 50%;
}

.sec6 {
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 100px 30px;
    background: url('../../assets/about/ABOUT_FIRST.jpg');
    height: 700px;
    display: flex;
    align-items: end;

}

.cardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.servicesContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 30px;
  margin: 30px 30px 20px 80px;
}

.serviceBox {
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: start;
}

.serviceBox img {
  width: 40px;
  height: 40px;
}

.serviceBox .contentBox {
  width: 80%;
}

.levelUp {
  display: flex;
  width: 100%;
  padding: 0 30px;
}

.levelUp img {
  width: 50%;
}

@media screen and (max-width: 900px) {
  .sec {
    margin: 15px 0px 20px 0px;
    padding: 0 20px;
  }
  
  .heroDiv {
    flex-direction: column;
  }

  .heroImg {
    width: 100%;
  }

  .sec3div {
    grid-template-columns: 100%;
  }

  .levelUp {
    flex-direction: column;
  }

  .levelUp img {
    width: 100%;
  }

  .sec2 {
    background-size: cover;
    height: auto;
    padding: 30px 10px;
  }

  .cardFooter {
    flex-direction: column;
  }

  .faqSec {
    flex-direction: column;
  }

  .faqDescription {
    width: 100%;
  }

  .faqs {
    width: 100%;
  }
}

.flexCss{
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute items horizontally with space between */
  align-items: center; /* Align items vertically in the center */
  gap: 5px; /* Space between the items */
  align-self: center;
}


@media screen and (max-width: 600px) {
  .sec {
    margin: 15px 0px 20px 0px;
    padding: 0 20px;
  }

  .heroDiv {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .heroImg {
    width: 100%;
  }

  .sec2 {
    background-size: cover;
    background-position: center;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .sec3 {
    margin: 20px;
    gap: 15px;
  }

  .sec3div {
    grid-template-columns: 100%;
    text-align: center;
  }

  .demoImg {
    width: 100%;
  }

  .sec4 {
    padding: 20px;
    text-align: center;
  }

  .sec5 {
    padding: 20px;
    background-size: cover;
    background-position: center;
  }

  .sec5 .dentistCardContainer {
    flex-direction: column;
    margin: 20px;
    gap: 20px;
  }

  .faqSec {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }

  .faqDescription {
    width: 100%;
    text-align: center;
  }

  .faqs {
    width: 100%;
  }

  .sec6 {
    padding: 20px;
    height: auto;
    background-size: cover;
    align-items: center;
    flex-direction: column;
  }

  .cardFooter {
    flex-direction: column;
    gap: 15px;
  }

  .servicesContainer {
    grid-template-columns: 100%;
    margin: 20px;
    gap: 20px;
  }

  .serviceBox {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .serviceBox img {
    width: 50px;
    height: 50px;
  }

  .serviceBox .contentBox {
    width: 100%;
  }

  .levelUp {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    text-align: center;
  }

  .levelUp img {
    width: 100%;
  }

  .flexCss {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }


  .blogsSec {
    padding: 20px; /* Add padding for mobile layout */
  }

  .divContainer {
    display: flex;
    flex-direction: column; /* Stack blog items vertically */
    gap: 15px; /* Add spacing between blog items */
  }

}

