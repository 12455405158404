.tabContainer {
  width: 100%;
  padding: 0 50px;
  margin: 0 auto;

}

.tabHeaders {
  display: flex;
  justify-content: space-around;
  border-bottom:1px solid #B0B0B0B5;
}

.tabButton {
  flex: 1;
  padding: 1rem;
  cursor: pointer;
  border: none;
  background-color: #fff;
  transition: border-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #515151;
}

.tabButton.active {
  /* border-bottom: 2px solid #007bff; */
  font-weight: bold;
  color: #006aff;
}

.tabContent {
  padding: 1rem;
  background-color: #fff;
}

.overviewCard {
  background-color: #fff;
  width: calc(95% / 3);
  height: 80px;
  border-radius: 10px;
  border: 1px solid rgba(105, 105, 105, 0.238);
  display: flex;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overview {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.overviewCard div img {
  width: 40px;
  height: 40px;
}

.overviewCard img {
  width: 25px;
  height: 25px;
}

.chapterContainer {
  width: 100%;
  padding: 10px 40px;
  max-height: 600px;
  overflow: auto;
}

.chapter {
  /* margin-bottom: 20px; */
  position: relative;
}

h2 {
  margin: 0;
  padding: 10px 0;
}
.circle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #006aff;
  border-radius: 20px;
  top: 0;
  z-index: 10;
}

.verticalLine {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  width: 2px;
  /* background-color: #ddd; */
  border-left: 2px dashed #006aff;
}

.accordionItem {
  margin-bottom: 10px;
  background-color: #d5edef;
  border-radius: 10px;
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
}

.accordionContent {
  padding: 10px;
}

.topicContainer {
  margin-left: 30px;
  margin-top: 10px;
}

.courseQA {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.accordionItem1 {
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f0f6f6;
}

.accordionHeader1 {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
}

.accordionHeader1 h3 {
  margin: 0;
  flex-grow: 1;
}

.accordionHeader1 .icon {
  margin-right: 10px;
}

.accordionContent1 {
  padding: 10px 10px 10px 30px;
}


.overviewHeader{
  padding: auto;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.moduleHeader{
  padding: auto;
  margin-left: 40px;
  margin-top: 10px;
  margin-bottom: 25px;
}




@media screen and (max-width: 600px) {
  .tabContainer {
    width: 100%;
    padding: 0 20px; /* Reduce padding for mobile */
    margin: 0 auto;
  }

  .tabHeaders {
    flex-wrap: wrap; /* Allow headers to wrap if needed */
    gap: 10px; /* Add gap for spacing */
    justify-content: space-between; /* Adjust alignment for smaller screens */
  }

  .tabButton {
    padding: 0.5rem; /* Reduce padding for compactness */
    font-size: 0.9rem; /* Adjust font size for better fit */
    flex: 1 1 45%; /* Allow flexible sizing */
    gap: 5px; /* Reduce gap between elements */
  }

  .tabContent {
    padding: 10px; /* Adjust padding for compactness */
  }

  .overview {
    flex-direction: column; /* Stack overview cards vertically */
    gap: 10px; /* Adjust gap for spacing */
    align-items: stretch; /* Make items stretch to fill container */
  }

  .overviewCard {
    width: 100%; /* Full width for mobile */
    height: auto; /* Adjust height dynamically */
    padding: 10px; /* Reduce padding */
  }

  .overviewCard div img,
  .overviewCard img {
    width: 30px; /* Reduce size for better fit */
    height: 30px;
  }

  .chapterContainer {
    padding: 10px 20px; /* Adjust padding for compactness */
    max-height: 400px; /* Reduce max height for smaller screens */
    overflow: auto;
  }

  .accordionItem {
    margin-bottom: 8px; /* Reduce margin for compact layout */
    border-radius: 8px;
  }

  .accordionHeader {
    padding: 8px; /* Adjust padding */
    font-size: 0.9rem; /* Adjust font size for better fit */
  }

  .accordionContent {
    padding: 8px; /* Reduce padding */
  }

  .topicContainer {
    margin-left: 20px; /* Reduce left margin */
    margin-top: 5px; /* Adjust top margin */
  }

  .courseQA {
    padding: 15px; /* Adjust padding for compact layout */
  }

  .accordionItem1 {
    margin-bottom: 8px; /* Reduce margin */
    border-radius: 5px;
  }

  .accordionHeader1 {
    padding: 8px; /* Reduce padding */
    font-size: 0.9rem; /* Adjust font size */
  }

  .accordionContent1 {
    padding: 8px 10px 8px 20px; /* Adjust padding */
  }

  .overviewHeader,
  .moduleHeader {
    margin-left: 10px; /* Adjust margin for compact design */
    margin-top: 5px; /* Reduce top margin */
    margin-bottom: 15px; /* Adjust bottom margin */
  }
}
