.blogCard {
    margin: 10px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.blogCard .blogImg {
    width: 100%;
}

.readMoreDiv {
    display: flex;
    justify-content: start;
    gap: 5px;
    align-items: center;
}


@media screen and (max-width: 600px) {
    .blogCard {
      margin: 5px; /* Reduce margin for compact layout */
      border-radius: 15px; /* Adjust border-radius for smaller screens */
      flex-direction: column; /* Maintain vertical stacking */
      text-align: center; /* Center-align text for better readability */
    }
  
    .blogCard .blogImg {
      width: 100%; /* Ensure full width for images */
      border-radius: 15px; /* Match the card's border-radius */
    }
  
    .readMoreDiv {
      justify-content: center; /* Center-align "Read More" content */
      gap: 10px; /* Increase gap slightly for better spacing */
      margin-top: 10px; /* Add spacing above the content */
    }
  }
  