.blogContent {
  padding: 50px;
  display: flex;
  gap: 40px;
}

.doctorCard {
  width: 400px;
  height: 500px;
}

.blog {
  width: 100%;
}

.docProfile {
  width: 100%;
  background-color: #f6f7f7;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.button {
  background-color: #cbf2fe;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 5px;
}

.share {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.socialConntainer {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.icon {
  background-color: #e7eae9;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.greenCard {
  padding: 30px;
  background-color: #004a5e;
  width: 100%;
  border-radius: 30px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

.blogImg {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 30px;
  margin-top: 30px;
}



@media screen and (max-width: 600px) {
  .blogContent {
    flex-direction: column; /* Stack items vertically */
    padding: 20px; /* Reduce padding for mobile screens */
    gap: 20px; /* Reduce gap between elements */
  }

  .doctorCard {
    width: 100%; /* Full width on mobile */
    height: auto; /* Adjust height dynamically */
  }

  .blog {
    width: 100%; /* Full width */
  }

  .docProfile {
    padding: 15px; /* Reduce padding for compact layout */
    border-radius: 15px; /* Adjust border-radius for mobile screens */
    gap: 10px; /* Maintain gap between elements */
  }

  .button {
    height: 35px; /* Adjust button height for smaller screens */
    border-radius: 5px; /* Maintain button shape */
    font-size: 12px; /* Adjust font size for readability */
  }

  .share {
    margin-top: 20px; /* Reduce top margin */
    gap: 10px; /* Adjust gap for spacing */
  }

  .socialConntainer {
    gap: 10px; /* Reduce gap between social icons */
    margin-top: 10px; /* Maintain spacing */
  }

  .icon {
    width: 35px; /* Adjust icon size */
    height: 35px; /* Match width and height */
    border-radius: 50%; /* Ensure circular icons */
  }

  .greenCard {
    padding: 15px; /* Reduce padding */
    border-radius: 20px; /* Adjust border-radius for mobile */
    gap: 10px; /* Reduce gap between elements */
    margin-top: 20px; /* Adjust top margin */
    margin-bottom: 20px; /* Adjust bottom margin */
  }

  .blogImg {
    width: 100%; /* Ensure full width */
    height: auto; /* Adjust height dynamically */
    border-radius: 20px; /* Adjust border-radius for mobile */
    margin-top: 20px; /* Adjust top margin */
  }
}
