.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 70px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);

  /* Default styles */
}

.navbar-brand-text {
  font-size: 1.5rem;
}

.navbar-nav {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 25px;
}

.nav-item {
  position: relative;
  cursor: pointer;
}

.nav-link {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: #fff; /* Default background color */
  padding: 10px 0;
  list-style-type: none;
  margin: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.dropdown-menu.active {
  display: block;
  opacity: 1;
  pointer-events: auto;
}

.dropdown-item {
  padding: 10px 20px;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover {
  background-color: #fff;
}

.navbar.light {
  background-color: #fff;
  color: #000; /* Light theme text color */
}

.dropdown-menu.light {
  background-color: #fff;
  color: #000; /* Light theme dropdown text color */
}

.dropdown-item.light:hover {
  background-color: #ddd; /* Light theme dropdown item hover color */
}

.navbar.dark {
  background-color: transparent; /* Dark theme background color */
  color: #fff; /* Dark theme text color */
}

.dropdown-menu.dark {
  background-color: #444; /* Dark theme dropdown background color */
  color: #fff; /* Dark theme dropdown text color */
}

.dropdown-item.dark:hover {
  background-color: #555; /* Dark theme dropdown item hover color */
}

.slide-container {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  background-color: #fff;
  transition: max-height 0.5s ease-in-out;
}

.slide-container.open {
  max-height: 500px; /* Adjust as needed */
  width: 100%;
}

.slide-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.cross {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0px;
}

.nav-item-mobile {
  border-bottom: 1px solid #c6c6c680;
  padding: 20px;
}

.hover-content {
  position: absolute;
  background-color: white;
  top: 292%;
  right: -700%;
  padding: 10px;
  z-index: 99;
  width: 800px;
  display: flex;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  gap: 10px;
  /* Add more styles as needed */
}

.navcard {
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
}

.navcard:hover {
  background-color: #1d4c92;
  border-radius: 10px;
}

.navcard h1 {
  text-transform: capitalize !important;
}

.navcard:hover h1 {
  color: #fff !important;
}

.navcard:hover h5 {
  color: #ffffff99 !important;
}

.navcard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 10px;
}




@media screen and (max-width: 950px) {
  .navbar {
    flex-direction: column;
    padding: 10px 20px; /* Reduce padding for smaller screens */
    box-shadow: none; /* Remove shadow for a cleaner look */
  }

  .navbar-brand-text {
    font-size: 1.2rem; /* Reduce font size for branding text */
    text-align: center; /* Center-align for better layout */
  }

  .navbar-brand-logo{
    width: 100px !important;
    display: flex ;
    justify-content: space-between;
  }
  .navbar.light {
    background-color: #fff;
    color: #000; /* Light theme text color */
  }
  .navbar-nav {
    flex-direction: column; /* Stack navigation items vertically */
    gap: 15px; /* Reduce spacing between items */
    width: 100%; /* Take full width of the screen */
    align-items: center;
  }

  .nav-item {
    text-align: center; /* Center-align items */
  }

  .nav-link {
    padding: 8px 10px; /* Adjust padding for smaller buttons */
  }

  .dropdown-menu {
    position: static; /* Make dropdown static for easier access */
    opacity: 1; /* Show dropdown by default */
    pointer-events: auto;
    background-color: #fff; /* Ensure background contrast */
    width: 100%; /* Expand to full width */
    box-shadow: none; /* Simplify design for mobile */
  }

  .dropdown-item {
    padding: 8px 15px; /* Reduce padding for dropdown items */
    text-align: center; /* Center-align text */
  }

  .slide-container {
    max-height: 300px; /* Reduce max height for mobile */
    width: 100%;
  }

  .slide-content {
    padding: 8px;
  }

  .cross {
    padding: 10px;
    justify-content: center; /* Center-align close button */
  }

  .hover-content {
    position: static; /* Remove absolute positioning for better layout */
    width: 100%; /* Take full width */
    flex-direction: column; /* Stack items vertically */
    gap: 15px; /* Increase spacing for clarity */
    border-radius: 10px; /* Reduce border radius */
  }

  .navcard {
    flex-direction: row; /* Align items horizontally */
    align-items: center;
    gap: 10px; /* Add spacing between icon and text */
    padding: 10px;
  }

  .navcard img {
    width: 40px; /* Scale down image size */
    height: 40px;
    margin: 0;
  }

  .navcard h1 {
    font-size: 1rem; /* Reduce font size */
  }

  .navcard h5 {
    font-size: 0.8rem; /* Adjust subtitle font size */
  }
}
