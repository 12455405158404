.profesion {
  background-color: #cdebff;
  width: 100vw;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 250px 50px 250px;
  text-align: center;
  gap: 10px;
}

.videoSection {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 30px;

}

.alwaysHere {
  /* background-image: url("../../assets/alwayshere.png"); */
  background-size: cover; /* Ensures the image covers the entire div */
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50px;
}

.spec {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  margin: 30px 0;
}

.specList {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  gap: 100px;
  width: 100%;
}

.specItem {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center the content horizontally */
  justify-content: center;
  /* Center the content vertically */
  text-align: center;
  /* Center the text inside the Textfield component */
}

.specItem img {
  width: 100px;
  margin-bottom: 10px;
}

.booknow {
  background-image: url("../../assets/home/TD_Section.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 700px;
  position: relative;
  padding-inline: 30px;
  /* Flexbox to center content */
  display: flex;
  justify-content: center;
  /* opacity: 0.9; */
  /* Horizontal centering */
  align-items: center;
  /* Vertical centering */
  background-color: rgba(255, 255, 255, 0.2); /* Adds a subtle white tint */
  background-blend-mode: lighten
}




.booknowItem {
  /* Optional: add some padding or max-width to the inner content */
  padding: 60px;
  max-width: 60%;
  min-width: 800px;
}

.bluesmallBanner {
  height: 125px;
  margin: 30px 65px;
  background-color: #356bbb;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px;
}

.hideInsideBookBtn {
  display: none;
}

.overlayStyle {
  position: relative;
  display: inline-block;
}

.overlayStyle img {
  display: block;
}

.blueOverlayStyle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px 60px 60px 30px;
  background-color: rgba(53, 107, 187, 0.3);
  /* Adjust opacity as needed */
}

.flexCol {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 50px;
}

.courses {
  text-align: center;
  padding: 0px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.courseContainer {
  width: 100%;
  margin-top: 40px;
  display: flex;
  gap: 50px;
}

.blueCard {
  width: 100%;
  background: #1D4C92;
  padding: 15px;
  height: 100%;
  border-radius: 20px;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.blueCard img {
  width: 100%;
  /* border-radius: 20px; */
}

.curved .shadow {
  position: relative;
  width: 500px;
  height: 250px;
}

.curved .shadow:after,
.curved .shadow::before {
  content: "";
  width: 70%;
  height: 80%;
  background-color: #000;
  position: absolute;
  left: 5%;
  bottom: 40px;
  transform-origin: right;
  transform: skewY(-8deg);
  opacity: 0.7;
  filter: blur(8px);
  z-index: 20;
}

.curved .shadow::before {
  left: 26%;
  bottom: 40px;
  transform-origin: left;
  transform: skewY(8deg);
}

.whiteButton {
  width: fit-content;
  border-bottom: 3px solid #8ccfff;
  border-radius: 10px;
  font-size: 12px;
  padding: 3px 10px;
  margin-bottom: 10px;
  color: #013a65;
  background-color: #fff;
}

.blueButton {
  width: fit-content;
  border-bottom: 1px solid #ffffff;
  border-radius: 10px;
  font-size: 12px;
  padding: 3px 10px;
  color: #fff;
  background-color: #8ccfff;
}

@media screen and (max-width: 900px) {
  .profesion {
    padding: 60px 30px 50px 30px;
  }

  .bluesmallBanner {
    flex-direction: column;
    align-items: center;
    height: auto;
    justify-content: center;
    padding-right: 0px;
    padding: 20px;
  }

  .hideOuterBookBtn {
    display: none;
  }

  .hideInsideBookBtn {
    width: fit-content;
    display: block;
  }

  .specList {
    flex-wrap: wrap;
  }

  .benefitsBox {
    margin-top: 50px !important;
  }
}

@media screen and (max-width: 700px) {
  .courseContainer {
    flex-direction: column;
  }

  .overlayStyle {
    width: 100px;
    gap: 0;
  }

  .overlayStyle img {
    width: 100px;
  }

  .flexCol {
    margin-left: 30px;
  }

  .specItem {
    width: 100%;
  }
}

.benefitsBox {
  transform: perspective(500px) rotateX(0deg) rotateY(25deg);
  width: 250px;
  height: 450px;
  margin: 0 0 50px 0;
  padding: 10px;
  backdrop-filter: blur(5px);
  background-color: rgba(19, 33, 115, 0.2);

  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.benefitsBox .benefits {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 30px;
  margin: 20px 10px 80px 10px;

}

.benefitsBox .benefitsBtn {
  border: 20px;
  padding: 10px 5px;
  margin-inline: auto !important;
  font-size: 10px !important;
}

.videoSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px; /* Space between video and text */
  padding: 20px;
}

.videoWrapper {
  flex: 1; /* Take equal space as the text */
}

.videoBackground {
  width: 100%; /* Full width of the container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Optional: Add border radius */
}

.textWrapper {
  flex: 1; /* Take equal space as the video */
  padding: 20px;
  text-align: left; /* Align text to the left */
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  line-height: 1.5;
  color: #333; /* Optional: Adjust text color */
}

.booknowItemMob{
  display: none;
}


@media screen and (max-width: 950px) {
  .profesion {
    padding: 20px; /* Reduce padding */
    height: auto; /* Adjust height dynamically */
    gap: 10px;
  }

  .videoSection {
    flex-direction: column; /* Stack content vertically */
    height: auto; /* Adjust height dynamically */
    padding: 20px; /* Reduce padding */
    gap: 20px;
  }

  .alwaysHere {
    height: auto; /* Allow height to adjust dynamically */
    border-radius: 20px; /* Reduce border-radius for better fit */
  }

  .spec {
    padding: 20px; /* Reduce padding */
    margin: 15px 0; /* Adjust margin */
  }

  .specList {
    flex-direction: column; /* Stack items vertically */
    gap: 30px; /* Adjust gap for spacing */
  }

  .specItem {
    width: 100%; /* Full width for mobile */
    gap: 10px; /* Adjust gap */
  }

  .specItem img {
    width: 80px; /* Reduce size for smaller screens */
    margin-bottom: 0px; /* Adjust margin */
  }

  .specItem h2 {
    padding-top: 3px; /* Adjust padding */
    padding-bottom: 3px; /* Adjust padding */
  }

  .booknow {
   
    padding-inline: 10px; /* Adjust horizontal padding */
    height: auto; /* Allow height to adjust dynamically */
    border-radius: 15px; /* Reduce border-radius */
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center content */
  }

  .booknowItem {
    display: none;
    padding: 20px; /* Reduce padding */
    width: 90%; /* Adjust width for mobile */
    max-width: 100%; /* Prevent exceeding container */
  }
  .booknowItemMob{
    display: block;
    padding: 20px; /* Reduce padding */
    width: 90%; /* Adjust width for mobile */
    max-width: 100%; /* Prevent exceeding container */
  }
  .bluesmallBanner {
    height: auto; /* Adjust height dynamically */
    flex-direction: column; /* Stack content vertically */
    padding: 15px; /* Reduce padding */
    gap: 10px; /* Adjust gap */
    margin: 20px; /* Reduce margin */
  }

  .overlayStyle {
    width: 100%; /* Full width for mobile */
  }

  .blueOverlayStyle {
    border-radius: 15px; /* Adjust border-radius */
  }

  .flexCol {
    gap: 5px; /* Adjust gap */
    margin-left: 20px; /* Reduce margin */
  }

  .courses {
    padding: 0 20px; /* Adjust padding */
  }

  .courseContainer {
    flex-direction: column; /* Stack items vertically */
    gap: 20px; /* Adjust gap */
  }

  .blueCard {
    padding: 10px; /* Reduce padding */
    gap: 10px; /* Adjust gap */
  }

  .blueCard img {
    width: 100%; /* Ensure image scales properly */
  }

  .curved .shadow {
    width: 100%; /* Full width for mobile */
    height: auto; /* Adjust height dynamically */
  }

  .whiteButton,
  .blueButton {
    font-size: 10px; /* Reduce font size for mobile */
    padding: 3px 8px; /* Adjust padding */
    margin-bottom: 5px; /* Reduce margin */
  }

  .videoSection {
    flex-direction: column; /* Stack video and text vertically */
    gap: 10px; /* Reduce gap for smaller screens */
  }

  .videoWrapper,
  .textWrapper {
    flex: unset; /* Remove equal spacing */
    width: 100%; /* Full width for both sections */
  }

  .title {
    font-size: 20px; /* Adjust title size */
  }

  .description {
    font-size: 14px; /* Adjust text size */
  }
}
