.hero {
  width: 100%;
  display: flex;
  /* padding: 0 70px; */
  /* p */
}

.heroText {
  width: 100%;
  height: 100%;
  padding: 0px 70px;
}

.heroImage {
  width: 100%;
  height: 60%;
  padding: 140px 30px 30px 0px;
}

.btnMain {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.playBtn {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 30px;
  transition: background-color 0.3s, border-color 0.3s;
  text-transform: uppercase;
  font-size: 12px;
  width: fit-content;
  margin: 5px 0;
  background-color: #ffffff5c;
  gap: 15px;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #fff;
  display: grid;
  place-content: center;
}

.banner {
  width: 90%;
  height: 350px;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px 10px 0px 10px;
}

.banner img {
  width: 100%;
  height: 88%;
  border-radius: 20px;
  object-fit: cover;
}

.bannerText {
  display: flex;
  height: 10%;
  display: flex;
  margin-left: 30px;
  justify-content: space-between;
  margin-right: 20px;
}

@media screen and (max-width: 1024px) {
  .hero {
    flex-direction: column;
    height: 100%;
  }

  .heroImage {
    padding: 10px 0px;
    display: grid;
    place-content: center;
    width: 100%;
  }

  .banner {
    width: 90vw;
  }
}

@media screen and (min-width: 1025px) {
  .hero {
    padding-top: 250px;
    padding-bottom: 150px;
  }
}


@media screen and (max-width: 600px) {
  .hero {
    flex-direction: column; /* Stack hero content vertically */
    align-items: center; /* Center-align content */
    padding: 0; /* Remove extra padding */
  }

  .heroText {
    padding: 10px 20px; /* Reduce padding for smaller screens */
    text-align: center; /* Center-align text for better readability */
  }

  .heroImage {
    width: 100%; /* Full width for mobile */
    height: auto; /* Adjust height dynamically */
    padding: 20px; /* Reduce padding for compact layout */
  }

  .btnMain {
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Reduce gap between buttons */
    margin-top: 20px; /* Adjust top margin */
    width: 100%; /* Full width for buttons */
    align-items: center; /* Center-align buttons */
  }

  .playBtn {
    font-size: 10px; /* Reduce font size */
    padding: 10px 15px; /* Adjust padding for smaller buttons */
    border-radius: 20px; /* Adjust border radius for a compact look */
  }

  .circle {
    width: 25px; /* Reduce size for smaller screens */
    height: 25px;
  }

  .banner {
    width: 100%; /* Full width for mobile */
    height: auto; /* Allow dynamic height */
    border-radius: 15px; /* Reduce border radius */
    padding: 10px; /* Adjust padding */
  }

  .banner img {
    width: 100%; /* Full width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 15px; /* Match container border radius */
  }

  .bannerText {
    flex-direction: column; /* Stack text and content vertically */
    margin: 10px; /* Add margin for spacing */
    text-align: center; /* Center-align content */
  }
}
