.courses {
  background-image: url("../../assets/course/Course_banner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  width: 100%;
  display: flex;
  padding-top: 160px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 10px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.headText {
  width: 100%;
  height: 100%;
  padding-right: 70px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 40px;
}

.enroll {
  border: 2px solid #ffbf00;
  border-radius: 20px;
  height: 40px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headImg {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.headImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 5px solid white;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* subtle shadow */
}

.blogsSec {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  padding: 60px;
}




@media screen and (max-width: 600px) {
  .courses {
    background-image: url("../../assets/course/Course_banner.jpg"); /* Ensure background is responsive */
    background-position: center;
    background-size: cover;
    height: auto; /* Adjust height dynamically */
    padding: 20px; /* Reduce padding for smaller screens */
    flex-direction: column; /* Stack content vertically */
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .headText {
    padding-right: 0; /* Remove right padding for compact layout */
    margin-top: 20px; /* Reduce top margin */
    text-align: center; /* Center align text */
    align-items: center; /* Center align items */
  }

  .enroll {
    border: 2px solid #ffbf00;
    border-radius: 15px;
    height: auto; /* Adjust height dynamically */
    padding: 10px;
    font-size: 0.9rem; /* Adjust font size for readability */
    margin-top: 15px; /* Add spacing for better layout */
  }

  .headImg {
    padding: 10px; /* Reduce padding */
    justify-content: center;
    align-items: center;
    border-radius: 15px;
  }

  .headImg img {
    width: 100%; /* Make image responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Adjust border-radius for smaller screens */
  }

  .blogsSec {
    grid-template-columns: 1fr; /* Single column layout */
    gap: 20px; /* Reduce gap for compact layout */
    padding: 20px; /* Adjust padding */
  }
}
