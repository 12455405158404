.dentistCard img {
    border-radius: 32px;
}

.dentistCard .dentistDetails {

    backdrop-filter: blur(20px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.12);
    padding: 30px;
    border-radius: 32px;
    width: 80%;
    position: relative;
    top: -150px;
    left: 45px;

}

@media screen and (max-width: 600px) {
    .dentistCard img {
      border-radius: 20px; /* Adjust border-radius for smaller screens */
      width: 100%; /* Ensure image is responsive */
      height: auto; /* Maintain aspect ratio */
    }
  
    .dentistCard .dentistDetails {
      backdrop-filter: blur(15px);
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      background-color: rgba(255, 255, 255, 0.2);
      padding: 20px;
      border-radius: 20px;
      width: 90%; /* Adjust width for smaller screens */
      top: -100px; /* Reduce offset for smaller screens */
      left: 0; /* Center the details on mobile */
      margin: 0 auto; /* Center horizontally */
    }
  }
  

