.topnav {
  height: 40px;
  padding: 0px 70px;
  display: flex;
  justify-content: space-between;
}

.social {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 15px;
  cursor: pointer;
}

.left,
.right {
  display: flex;
}

.iconButton {
  padding: 5px;
  width: 25px;
  height: 25px;
  display: grid;
  place-content: center;
  border-radius: 100%;
}


@media screen and (max-width: 600px) {
  .topnav {
    height: auto; /* Adjust height dynamically */
    padding: 10px 20px; /* Reduce padding for smaller screens */
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center-align content */
    gap: 10px; /* Add spacing between stacked items */
  }

  .social {
    gap: 10px; /* Increase spacing between icons */
    margin-right: 0; /* Remove unnecessary margin on smaller screens */
  }

  .left,
  .right {
    flex-direction: row; /* Maintain horizontal alignment */
    justify-content: center; /* Center-align content for consistency */
  }

  .iconButton {
    padding: 8px; /* Increase padding for better tap interaction */
    width: 30px; /* Increase size for touch-friendly design */
    height: 30px;
    border-radius: 50%; /* Maintain circular design */
  }

  .nav {
    display: flex;
    flex-direction: column; /* Stack phone number and social icons vertically */
    align-items: center; /* Center-align for better readability */
    text-align: center; /* Center-align text */
    gap: 10px; /* Add spacing between elements */
    padding: 10px 20px; /* Adjust padding */
  }

  .nav .phone {
    font-size: 14px; /* Adjust font size for readability */
    color: #000; /* Ensure good contrast */
  }

  .nav .social {
    justify-content: center; /* Center-align social icons */
    gap: 15px; /* Add spacing between social icons */
  }
}
