.work {
  background-color: #f0f9ff;
  height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding: 40px;
  text-align: center;
}

.steps {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
  padding-top: 40px;
  padding-bottom: 20px;
}

.step {
  width: 100%;
  height: 100%;
}

.step img {
  width: 300px;
  height: 300px;
}


@media screen and (max-width: 600px) {
  .work {
    height: auto; /* Allow dynamic height */
    padding: 20px; /* Reduce padding for smaller screens */
    text-align: center; /* Center align text for better readability */
  }

  .steps {
    flex-direction: column; /* Stack steps vertically */
    gap: 15px; /* Reduce gap for compact layout */
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .step {
    width: 100%; /* Ensure each step takes full width */
    height: auto; /* Adjust height dynamically */
  }

  .step img {
    width: 100%; /* Scale image to fit container */
    max-width: 200px; /* Limit max width for better scaling */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center image within its container */
  }
}
