.layout {
  /* position: relative; */
  width: 100vw;
  height: 100vh;
}

.nav {
  position: fixed;
  z-index: 100;
  width: 100vw;
  top: 0;
}

.header {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../assets/home/HOME_BANNER.png");
  width: 100vw;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  border-bottom-left-radius: 80px;
  border-bottom-right-radius: 80px;
}

@media screen and (max-width: 1024px) {

  .header {
    /* height: 100vh; */
    background-size: cover;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding-top: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 1025px) {
  .header {
    height: 85vh;
    background-size: cover;
  }
}





@media screen and (max-width: 950px) {
  .layout {
    width: 100%;
    /* width: 100vw; Maintain full width */
    height: auto; /* Adjust height dynamically */
    overflow-x: hidden;
  }

  .nav {
    position: fixed;
    z-index: 100;
    width: 100%; /* Ensure full width on mobile */
    top: 20 !important;
  }

  .header {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      ),
      url("../../assets/home/HOME_BANNER.png");
    background-repeat: no-repeat;
    background-size: cover; /* Adjust background size for responsiveness */
    background-position: center;
    border-bottom-left-radius: 40px; /* Reduce radius for smaller screens */
    border-bottom-right-radius: 40px;
    height: auto; /* Adjust height dynamically */
    padding: 20px; /* Add padding for content */
  }
}
