/* Animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-420px * 7));
  }
}

/* Styling */
.slider {
  background: white;
  height: 350px !important;
  overflow: hidden;
  width: 100vw;
  position: relative;
}

.slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(320px * 14); /* Adjust for the correct number of slides */
  gap: 20px;
}

.slide {
  width: 320px;
  height: 300px;
}

.slide img {
  width: 320px;
  height: 300px;
  object-fit: cover;
}

.background {
  background-color: #cdebff;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 150px;
}

.circle1 {
  background-color: #cdebff;
  position: absolute;
  top: -35px;
  width: 100vw;
  height: 70px;
  z-index: 2;
  border-radius: 50%;
}

.circle2 {
  background-color: #fff;
  position: absolute;
  bottom: -35px;
  width: 100vw;
  height: 70px;
  z-index: 2;
  border-radius: 50%;
}
