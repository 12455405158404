/* #023a65 */

.footer {
  background-color: #023a65;
  width: 100vw;
}

.footerMain {
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 25px 30px 40px 30px;
}

.mobileFlex {
  display: flex;
  width: 100%;
}

.logo {
  height: 80%;
  width: 100%;
  font-size: 3rem;
  color: white;
  font-weight: 700;
  margin-top: 30px;
}
.worktime {
  width: 250px;
  margin-top: 30px;
}
.services {
  width: 250px;
  margin-top: 30px;
}
.outclinics {
  height: 80%;
  width: 100%;
  margin-top: 30px;
}

.copyright {
  width: 100vw;
  height: 5%;
  text-align: center;
  padding: 15px;
  border-top: 1px solid #ffffff5c;
  color: #ffffff5c !important;
}

@media screen and (max-width: 900px) {
  .footerMain {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .logo {
    padding-bottom: 20px;
    border-bottom: 1px solid #ffffff1a;
  }

  .worktime {
    width: 100%;
    margin-top: 30px;
  }
  .services {
    width: 100%;
    margin-top: 30px;
  }
}



@media screen and (max-width: 600px) {
  .footer {
    padding: 20px;
    text-align: center;
  }

  .footerMain {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
  }

  .logo {
    font-size: 2.5rem;
    margin-top: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ffffff1a;
    width: 100%;
    text-align: center;
  }

  .worktime,
  .services,
  .outclinics {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }

  .copyright {
    font-size: 0.8rem;
    padding: 10px;
  }
}
