.smileContainer {
  margin-top: 20px;
  width: 100vw;
  height: 500px;
  /* height: auto; */
  background-color: #f0f9ff;
  padding: 30px;
}

.whiteContainer {
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
  padding: 30px;
}

.infoContainer {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.imageContainer {
  width: 40%;
  height: 100%;
  background-color: green;
  border-radius: 20px;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.item {
  padding: 10px;
  display: flex;
  gap: 10px;
}

.numbercirlce {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #c7e0f2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.number {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.itemContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.info {
  width: 80%;
}



@media screen and (max-width: 600px) {
  .smileContainer {
    margin-top: 10px; /* Reduce margin for compact layout */
    height: auto; /* Adjust height dynamically */
    padding: 20px; /* Reduce padding */
  }

  .whiteContainer {
    flex-direction: column; /* Stack content vertically */
    gap: 15px; /* Adjust gap for spacing */
    padding: 20px; /* Reduce padding */
  }

  .infoContainer {
    width: 100%; /* Full width for mobile */
    height: auto; /* Adjust height dynamically */
    flex-direction: column; /* Maintain column layout */
    text-align: center; /* Center-align text for readability */
  }

  .imageContainer {
    width: 100%; /* Full width for mobile */
    height: 200px; /* Limit height for smaller screens */
    border-radius: 15px; /* Adjust border-radius for better fit */
  }

  .imageContainer img {
    width: 100%; /* Full width */
    height: 100%; /* Maintain full height */
    object-fit: cover; /* Keep aspect ratio */
    border-radius: 15px; /* Match container border-radius */
  }

  .item {
    padding: 5px; /* Reduce padding */
    gap: 10px; /* Maintain gap between elements */
    flex-direction: column; /* Stack item content vertically */
  }

  .numbercirlce {
    width: 40px; /* Reduce size for mobile */
    height: 40px;
    border-radius: 100%;
    padding: 4px; /* Adjust padding */
  }

  .number {
    width: 100%;
    height: 100%;
    border-radius: 100%; /* Maintain circular design */
  }

  .itemContainer {
    grid-template-columns: 1fr; /* Single-column layout for mobile */
    gap: 15px; /* Reduce gap for compact spacing */
  }

  .info {
    width: 100%; /* Full width for mobile */
    text-align: center; /* Center-align text */
  }
}
