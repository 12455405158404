.formSec {
    background-image: url('../../assets/contactFormBg.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 120px 0px 0px 0px;
    padding: 30px;
}

.certificateSec {
    padding: 30px;
}

.contImg {
    width: 40%;
}

.certificateFeatures {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}



.featuresBoxContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
}

.featuresBox {
    display: flex;
    align-items: center;  /* Vertically center the image and text */
    justify-content: flex-start;  /* Align items to the start */
    padding: 20px;
    border-radius: 16px;
    background-color: #F0F9FF;
    gap: 15px;
    width: 100%;
    min-height: 120px;  /* Ensure a consistent minimum height */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);  /* Optional: Add a subtle shadow */
    text-align: left;  /* Align text to the left */
}

.featuresBox img {
    border-radius: 16px;
    width: 70px;
    height: 70px;
    flex-shrink: 0;
}

.featuresBox div {
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Vertically center the text within the text container */
}

@media screen and (max-width: 900px) {

    .formSec {
        padding: 15px;
    }

    .certificateFeatures {
        flex-direction: column;
    }

    .contImg {
        width: 100%;
    }

}


@media screen and (max-width: 600px) {
    .formSec {
      margin: 15px 0 0 0; /* Reduce top margin */
      padding: 20px; /* Adjust padding for smaller screens */
      background-size: cover; /* Maintain proper scaling for background */
    }
  
    .certificateSec {
      padding: 15px; /* Reduce padding for compact layout */
    }
  
    .contImg {
      width: 100%; /* Full width for mobile */
      height: auto; /* Maintain aspect ratio */
    }
  
    .certificateFeatures {
      flex-direction: column; /* Stack items vertically */
      gap: 15px; /* Adjust gap for compact spacing */
    }
  
    .featuresBoxContainer {
      gap: 15px; /* Adjust gap between feature boxes */
      padding: 10px; /* Add padding for better spacing */
    }
  
    .featuresBox {
      flex-direction: column; /* Stack image and text vertically */
      align-items: center; /* Center-align content */
      justify-content: center; /* Center-align items */
      padding: 15px; /* Reduce padding for compact layout */
      gap: 10px; /* Adjust gap for spacing */
      text-align: center; /* Center-align text for readability */
      min-height: auto; /* Allow height to adjust dynamically */
    }
  
    .featuresBox img {
      width: 50px; /* Reduce image size for mobile */
      height: 50px; /* Keep consistent aspect ratio */
    }
  
    .featuresBox div {
      text-align: center; /* Center-align text */
    }
  }
  