.formSec {
  background-image: url("../../assets/course/Hero_Banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 120px 0px 0px 0px;
  padding: 30px;
  height: 400px;
  background-color: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  display: flex;
  align-items: center;
}

.coursesCardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 30px 70px;
  background-color: rgb(230, 249, 255);
}

.certificateSec {
  padding: 30px;
}

.contImg {
  width: 40%;
}
.certificateFeatures {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center; /* Vertically center the content */
  padding: 20px;
}

.featuresBox {
  display: flex;
  align-items: center; /* Vertically center the content within each box */
  padding: 20px;
  border-radius: 16px;
  background-color: #f0f9ff;
  gap: 15px;
  width: 100%; /* Make the box take up the full width of the container */
  min-height: 120px; /* Ensure a consistent height */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}

.featuresBox img {
  border-radius: 16px;
  width: 50px; /* Adjust image size as needed */
  height: 50px; /* Maintain consistent height and width */
  flex-shrink: 0; /* Prevent the image from shrinking */
}

.featuresBoxContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: stretch; /* Stretch items to fill the container */
}

.featuresBox div {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the text vertically within the box */
}

.contImg {
  max-width: 100%;
  height: auto;
  border-radius: 16px;
}

@media screen and (max-width: 900px) {
  .formSec {
    margin: 15px 0;
  }

  .coursesCardContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .certificateFeatures {
    flex-direction: column;
  }

  .contImg {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .coursesCardContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .formSec {
    background-image: url("../../assets/course/Hero_Banner.png"); /* Ensure image responsiveness */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 15px 0; /* Reduce margin for compact layout */
    padding: 20px; /* Adjust padding for smaller screens */
    height: auto; /* Allow height to adjust dynamically */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center align content */
    align-items: center;
  }

  .coursesCardContainer {
    grid-template-columns: 1fr; /* Single column layout */
    gap: 15px; /* Reduce gap for compact design */
    padding: 20px; /* Adjust padding */
  }

  .certificateSec {
    padding: 20px; /* Reduce padding for smaller screens */
  }

  .certificateFeatures {
    flex-direction: column; /* Stack items vertically */
    gap: 15px; /* Reduce gap for compact design */
    padding: 15px; /* Adjust padding */
  }

  .featuresBox {
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center align content */
    text-align: center; /* Center text alignment */
    gap: 10px; /* Reduce gap for compact layout */
    padding: 15px; /* Adjust padding */
  }

  .featuresBox img {
    width: 40px; /* Reduce size for smaller screens */
    height: 40px;
  }

  .featuresBoxContainer {
    gap: 15px; /* Reduce gap between feature boxes */
    padding: 10px; /* Adjust padding */
  }

  .contImg {
    width: 100%; /* Make image take full width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Slightly reduce border radius for smaller screens */
  }
}
