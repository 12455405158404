.carouselContainer {
    perspective: 1000px;
    width: 100%;
    min-width: fit-content;
    height: 450px;
    position: relative;
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.carousel {
    display: flex;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);
    /* Smooth easing function */
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex-wrap: nowrap;
}

.carouselItem {
    flex: 0 0 auto;
    min-width: 350px;
    max-width: 350px;
    width: 350px;
    transition: transform 0.8s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 0.8s ease-in-out;
    position: relative;
    transform-origin: center center;
    opacity: 0.5; /* Lower opacity for non-active items */
    transform: translateZ(-200px); /* Push non-active items slightly back */
    margin: 0 15px;
    padding: 0;
    backdrop-filter: blur(5px);
}

.carouselItem.active {
    transform: translateX(0%) scale(1.2) translateZ(0) ; /* Scale and bring active item forward */
    opacity: 1; /* Full opacity for active item */
    z-index: 10; /* Ensure active item is in front */
}

.carouselItem.prev,
.carouselItem.next {
    transform: translateX(-50%) scale(0.8) translateZ(-100px); /* Slightly smaller and pushed back */
    opacity: 0.5;
    z-index: 5; /* Slightly lower z-index */
}

.carouselItem.prev {
    transform: translateX(-100%) scale(0.8) translateZ(-150px); /* Move prev item left and shrink it */
}

.carouselItem.next {
    transform: translateX(100%) scale(0.8) translateZ(-150px); /* Move next item right and shrink it */
}

.card {
    background-color: rgba(0, 163, 255, 0.2);
    background-size: cover;
    border-radius: 10px;
    padding: 10px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    width: 500px;
    height: 450px;
}

.card img {
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.cardContent {
    padding-block: 20px;
    text-align: left;
    color: #000; /* Black text color */
}

.badge {
    display: inline-block;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border-radius: 20px;
    font-size: 12px;
    margin-bottom: 10px;
}

.cardContent h3 {
    margin: 10px 0;
    font-size: 18px;
    color: #000; /* Ensure header text is black */
}

.cardContent p {
    margin: 0;
    color: #000; /* Ensure paragraph text is black */
    font-size: 14px;
}

.navigation {
    width: 500px;
    display: flex;
    justify-content: space-between;
}

.navigation button {
    background: rgba(25, 136, 255, 0.7) !important;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 18px;
    background-color: rgba(25, 136, 255, 0.2); 
    backdrop-filter: blur(5px);
    color: white;
}


/*  */

@media screen and (max-width: 768px) {
    .carouselContainer {
        perspective: 800px;
        width: 100%;
        height: 300px;
        margin: 0 auto;
    }

    .carousel {
        display: flex;
        flex-wrap: nowrap;
        transform-style: preserve-3d;
        transition: transform 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);
    }

    .carouselItem {
        min-width: 250px;
        max-width: 250px;
        width: 250px;
        height: 300px;
        transform: translateZ(-150px);
        opacity: 0.5;
        margin: 0 10px;
        padding: 0;
    }

    .carouselItem.active {
        transform: translateX(0%) scale(1.1) translateZ(0);
        opacity: 1;
        z-index: 10;
    }

    .carouselItem.prev,
    .carouselItem.next {
        transform: translateX(-50%) scale(0.8) translateZ(-100px);
        opacity: 0.5;
        z-index: 5;
    }

    .carouselItem.prev {
        transform: translateX(-100%) scale(0.8) translateZ(-120px);
    }

    .carouselItem.next {
        transform: translateX(100%) scale(0.8) translateZ(-120px);
    }

    .card {
        background-color: rgba(0, 163, 255, 0.1);
        border-radius: 8px;
        padding: 8px;
        width: 100%;
        height: 100%;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }

    .card img {
        width: 100%;
        height: auto;
        border-radius: 8px;
        margin-bottom: 10px;
    }

    .cardContent {
        padding: 10px;
        text-align: center;
    }

    .badge {
        font-size: 10px;
        padding: 4px 8px;
    }

    .navigation {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        top: -20px;
    }

    .navigation button {
        width: 40px;
        height: 40px;
        font-size: 16px;
    }
}
