.tabContainer {
    background-color: rgba(0, 43, 74, 1);
    border-radius: 80px;
    padding: 20px 30px;
    /* box-shadow: 0px 8px 40px 0px #0000001A; */
    display: flex;
    gap: 50px;
    justify-content: center;
    width: fit-content;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

.tab {
    padding: 20px;
    border-radius: 64px;
    cursor: pointer;
}


@media screen and (max-width: 600px) {
    .tabContainer {
      flex-direction: column; /* Stack tabs vertically */
      gap: 20px; /* Reduce spacing between tabs */
      padding: 15px 20px; /* Adjust padding for compact layout */
      width: 100%; /* Full width for better alignment on smaller screens */
      border-radius: 20px; /* Adjust border-radius for smaller screens */
    }
  
    .tab {
      padding: 10px 15px; /* Reduce padding for compact design */
      border-radius: 32px; /* Adjust border-radius for better fit */
      text-align: center; /* Center-align tab text */
      font-size: 14px; /* Adjust font size for readability */
    }
  }
  