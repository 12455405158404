.cardWrapper {
    border-radius: 30px;
    background-color: var(--DarkBlue);
    color: white;
    margin: 10px;
    padding: 30px;
    box-shadow: 0px 10px 20px 0px #00000026;
}


@media screen and (max-width: 600px) {
    .cardWrapper {
      border-radius: 20px; /* Reduce border-radius for smaller screens */
      margin: 5px; /* Reduce margin for compact layout */
      padding: 15px; /* Adjust padding for a more compact design */
      box-shadow: 0px 5px 10px 0px #00000026; /* Subtle shadow for smaller screens */
    }
  }
  