.contactContainer {
  display: flex;
  width: 100vw;
  height: 550px;
}

.form {
  width: 100%;
  height: 100%;
  background-color: #c9e2ff;
  padding: 70px 50px;
}

.image {
  width: 100%;
  height: 100%;
}

.image > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.formMain {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.flexform {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media screen and (max-width: 900px) {
  .contactContainer {
    flex-direction: column;
    height: auto;
  }
  .flexform {
    flex-direction: column;
    gap: 0;
    width: 100%;
  }

  .formMain {
    align-items: center;
  }
  .image {
    height: 400px;
  }
}


@media screen and (max-width: 600px) {
  .contactContainer {
    flex-direction: column; /* Stack form and image vertically */
    height: auto; /* Allow dynamic height adjustment */
  }

  .form {
    width: 100%; /* Full width on mobile */
    padding: 30px 20px; /* Reduce padding for compact layout */
    background-color: #c9e2ff; /* Maintain background color */
  }

  .image {
    width: 100%; /* Full width for the image */
    height: 300px; /* Adjust height for better fit */
  }

  .image > img {
    object-fit: cover; /* Ensure the image scales properly */
    width: 100%; /* Full width */
    height: 100%; /* Match container height */
  }

  .formMain {
    margin-top: 20px; /* Reduce margin for compact spacing */
  }

  .flexform {
    flex-direction: column; /* Stack form fields vertically */
    gap: 15px; /* Adjust gap between fields */
  }
}
