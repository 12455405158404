.pricing {
  width: 100%;
  padding-top: 300px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 70px;
}

.pricingContainer {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
  width: 80%;
  height: 600px;
  border-radius: 20px;
  display: flex;
  padding: 50px;
  gap: 15px;
}

.accordionContainer {
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.bluePricing {
  background-color: rgba(240, 249, 255, 1);
  box-shadow: 0px 10px 30px 0px rgba(10, 67, 109, 0.2);
  border-radius: 20px;
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
}

.whitePricing {
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
}

.acordion {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.accordionTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 40px;
}

.icon {
  padding: 14px 40px;
}

.icon1 {
  padding: 22px 40px;
}

@media screen and (max-width: 768px) {
  /* Add a media query for mobile view */
  .pricing {
    padding-top: 80px;
  }

  .pricingContainer {
    overflow-x: auto; /* Enables horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    width: 100%; /* Allow the container to fit within the viewport */
    height: 600px;
    font-size: small;
  }

  .accordionContainer {
    min-width: 220px;
  }

  .bluePricing,
  .whitePricing {
    min-width: 220px; /* Adjust widths to fit within scrollable container */
  }

  .whitePricing {
    padding: 28px 0 25px 0;
  }
}
