.blog {
  margin-top: 160px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
  display: flex;
  background-color: #e0efef;
  border-radius: 30px;
  height: 550px;
}

.blogHeader {
  width: 100%;
}

.blogHeader img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 50px;
}

.avtarContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}

.avtarContainer img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 20px;
}

.blogsSec {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  padding: 60px;
}



@media screen and (max-width: 600px) {
  .blog {
    flex-direction: column; /* Stack content vertically */
    margin: 20px; /* Reduce margins for smaller screens */
    height: auto; /* Allow height to adjust dynamically */
    border-radius: 20px; /* Adjust border-radius for compact design */
  }

  .blogHeader img {
    border-top-left-radius: 20px; /* Match the container's border-radius */
    border-bottom-left-radius: 0; /* Remove unnecessary radius for vertical stacking */
    border-top-right-radius: 20px; /* Add radius for top-right corner */
    width: 100%; /* Full width */
    height: auto; /* Maintain aspect ratio */
  }

  .info {
    padding: 20px; /* Reduce padding for a more compact layout */
    align-items: center; /* Center-align text */
    text-align: center; /* Ensure text looks centered */
  }

  .avtarContainer {
    gap: 10px; /* Reduce gap between avatar and text */
  }

  .avtarContainer img {
    width: 25px; /* Adjust avatar size for smaller screens */
    height: 25px;
  }

  .blogsSec {
    grid-template-columns: repeat(1, 1fr); /* Single-column layout for mobile */
    gap: 20px; /* Reduce gap between cards */
    padding: 20px; /* Adjust padding for compact design */
  }
}
