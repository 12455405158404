.coursespec {
  width: 100%;
  display: flex;
  gap: 50px;
  align-items: end;
}

.imgDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

}

.doctor {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  background-image: url('../../assets/course/proffesorBg.svg');
  background-position: right bottom;
  background-size: 850px 600px;
  height: 600px;
  background-repeat: no-repeat;
  align-items: center;
  overflow: hidden;
  padding: 10px;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.doctor.show {
  opacity: 1;
  visibility: visible;
}

.doctor.hide {
  opacity: 0;
  /* visibility: hidden; */
  display: none;
}
.doctor .docImg {
  width: 450px;
  height: 630px;
  position: relative;
}

.infoGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.infoGrid span {
  display: flex;
  gap: 10px;
}

.detailsDiv {
  width: max-content;
  position: relative;
  left: 15px;
  top: -20px;
  margin-left: 30px;
}

.cardFooter {
  display: flex;
  justify-content: end;
}



@media screen and (max-width: 950px) {
  .coursespec {
    flex-direction: column; /* Stack elements vertically */
    gap: 20px; /* Reduce gap */
    align-items: center; /* Center-align content */
    width: 100%;
  }

  .imgDiv {
    justify-content: center;
   /* Adjust margin for compact layout */
  }

  .doctor {
    background-size: cover; /* Adjust background size for responsiveness */
    height: auto; /* Adjust height dynamically */
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center-align content */
    padding: 20px; /* Add consistent padding */
  }

  .doctor .docImg {
    width: 100%; /* Full width for smaller screens */
    height: auto; /* Maintain aspect ratio */
    position: relative;
  }

  .infoGrid {
    grid-template-columns: 1fr; /* Single-column layout */
    gap: 15px; /* Reduce gap for compact layout */
  }

  .infoGrid span {
    flex-direction: column; /* Stack elements vertically */
    gap: 5px; /* Adjust gap between items */
  }

  .detailsDiv {
    width: 100%; /* Adjust to full width */
    position: relative;
    left: 0; /* Reset positioning */
    top: 0; /* Reset positioning */
    margin-left: 0; /* Reset margin */
    margin-top: 10px; /* Add top margin */
    text-align: center; /* Center-align text */
  }

  .cardFooter {
    justify-content: center; /* Center-align footer content */
    padding: 10px; /* Add padding for spacing */
  }
}
