.certiContainer {
  height: 500px;
  width: 100%;
  background-color: #e4effd;
  padding-top: 50px;
  padding-right:70px ;
  padding-left:70px ;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnMain {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.playBtn {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 30px;
  transition: background-color 0.3s, border-color 0.3s;
  text-transform: uppercase;
  font-size: 12px;
  width: fit-content;
  margin: 5px 0;
  background-color: #1d4c92;
  gap: 15px;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.27);
  display: grid;
  place-content: center;
}

.stackedContainer {
  display: flex;
  position: relative;
  height: 700px;
  margin-top: 20px;
}

.left2 {
  width: 300px;
  position: absolute;
  right: 350px;
  top: 50%;
  object-fit: cover;
  z-index: 18;
  border-radius: 20px;
}

.left {
  width: 500px;
  position: absolute;
  right: 0px;
  top: 20%;
  object-fit: cover;
  border-radius: 20px;
  z-index: 19;
}

.center {
  width: 600px;
  position: absolute;
  right: -300px;
  z-index: 20;
  border-radius: 20px;
  object-fit: cover;
}

.right2 {
  width: 300px;
  position: absolute;
  left: 350px;
  top: 50%;
  object-fit: cover;
  z-index: 18;
  border-radius: 20px;
}

.right {
  width: 500px;
  object-fit: cover;
  position: absolute;
  left: 0px;
  top: 20%;
  object-fit: cover;
  z-index: 19;
  border-radius: 20px;
}




@media screen and (max-width: 600px) {
  .certiContainer {
    height: auto; /* Adjust height dynamically */
    padding: 20px; /* Reduce padding for smaller screens */
    background-color: #e4effd;
    align-items: center;
  }

  .btnMain {
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Reduce gap between buttons */
    margin-top: 20px;
    align-items: center; /* Center buttons */
  }

  .playBtn {
    padding: 10px 15px; /* Reduce padding for compact buttons */
    font-size: 12px; /* Adjust font size for smaller screens */
    gap: 10px; /* Reduce gap between button content */
    border-radius: 20px; /* Adjust radius for better proportions */
  }

  .circle {
    width: 25px; /* Adjust size for smaller screens */
    height: 25px;
  }

  .stackedContainer {
    height: auto; /* Adjust height dynamically */
    margin-top: 15px;
    flex-direction: column; /* Stack images vertically */
    align-items: center; /* Center align content */
  }

  .left2,
  .left,
  .center,
  .right2,
  .right {
    width: 80%; /* Adjust width for mobile screens */
    position: relative; /* Remove absolute positioning for better stacking */
    margin-bottom: 15px; /* Add spacing between images */
    border-radius: 15px; /* Adjust radius for better fit */
    top: auto; /* Reset top positioning */
    right: auto; /* Reset right positioning */
    left: auto; /* Reset left positioning */
  }

  .center {
    width: 90%; /* Make the center image slightly larger for emphasis */
  }
}
